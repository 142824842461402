import GestorObd from "./gestorObd"
import userService from "@/services/userService"
import onboardingService from "@/services/onboardingService"
import constants from "@/constants"

class GestorSixClovers extends GestorObd {

  async signUpService(parametros) {
    const {
      SignUpForm,
      UserDataForm,
      UploadDocuments,
      TerminosCondiciones
    } = parametros

    let payload = {
      client_ori: constants.ORIGIN_MASLOW
    }

    if (SignUpForm) {
      payload = {
        ...payload,
        email: SignUpForm.email,
        password: SignUpForm.password1,
        password_conf: SignUpForm.password2
      }
    }

    if (TerminosCondiciones) {
      payload = {
        ...payload,
        politically_exposed: TerminosCondiciones.no_politic
      }
    }

    if (UserDataForm) {
      payload = {
        ...payload,
        first_name: UserDataForm.name,
        surname: UserDataForm.surname,
        client_cuit: parseInt(UserDataForm.cuit),
        client_phone: UserDataForm.phone_number,
        client_bir_dat: UserDataForm.birth_date,
        client_gender: UserDataForm.gender,
        client_cbu: UserDataForm.cbu,
        client_add_str: UserDataForm.street,
        client_add_num: UserDataForm.street_number,
        client_add_floor: UserDataForm.piso,
        client_add_atp: UserDataForm.dpto,
        client_add_city: UserDataForm.city,
        client_add_pc: UserDataForm.cp,
        province_id: UserDataForm.province_id,
        province_name: UserDataForm.state,
        country_id: UserDataForm.country_id,
        sixclovers_nat: UserDataForm.nacionalidad_six_clovers,
        sixclovers_country: UserDataForm.pais_six_clovers
      }
    }

    if (UploadDocuments) {
      payload = {
        ...payload,
        doc_frente_dni: UploadDocuments.dniFront,
        type_frente_dni: UploadDocuments.typeFront,
        doc_dorso_dni: UploadDocuments.dniBack,
        type_dorso_dni: UploadDocuments.typeBack,
        doc_selfie: UploadDocuments.dniAndSelfie,
        type_selfie: UploadDocuments.typeSelfie
      }
    }

    return await userService.signUpMaslow(payload)
  }

  getComponents() {
    onboardingService
    .getComponentsByOrigin(constants.ORIGIN_SIX_CLOVERS)
      // .getComponentsByOrigin(constants.ORIGIN_MASLOW) // TODO: descomentar cuando hay origen de Maslow en BO
      .then(response => {
        this.components = response.data.origins
          .map(item => {
            const newItem = {
              visible: item.visible,
              pantalla: item.component
            }
            return newItem
          })
          .filter(p => p.visible)
      })
  }
}

export default GestorSixClovers
