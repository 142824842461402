<template>
  <b-container fluid class="mt-5">
    <b-row>
      <b-col class="text-center">
        <b-img class="logo-six-clovers" src="@/assets/maslow-logo.png"></b-img>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col xs="12" sm="8" md="6" lg="5" class="max-obd-width">
        <keep-alive>
          <component
            @confirm-step="confirmStep"
            :is="currentComponent"
            :response="response"
            :loading="loading"
            :personType="personType"
            v-if="gestor.hasLoadedComponents()"
            v-bind="{
              currentStep:gestor.getCurrentStep(),
              maxSteps:gestor.maxStep()
            }"
          ></component>
        </keep-alive>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <span class="text-muted copyright">Powered by</span>
        <b-img src="@/assets/bithan.svg"></b-img> <template v-if="gestor"></template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import "@/assets/scss/maslow/theme.scss"
// import "@/assets/scss/maslow/main.scss"
import "@/assets/scss/theme-maslow.scss"
import "@/assets/scss/main-maslow.scss"
import GestorMaslow from "@/components/onboarding/gestores/gestorMaslow.js"

export default {
  name: "Main",
  components: {
    onboardingEnd: () => import("@/components/onboarding/FinishOnboarding"),
    SignUpForm: () => import("@/components/onboarding/SignUpMaslow"),
    UserDataForm: () => import("@/components/onboarding/DebitForm.vue"),
    UploadDocuments: () => import("@/components/onboarding/UploadDni"),
    TerminosCondiciones: () =>
      import("@/components/onboarding/TerminosCondiciones")
  },
  created() {
    this.gestor = new GestorMaslow()
    this.gestor.getComponents();
  },
  data() {
    return {
      gestor: null,
      response: "",
      loading: "",
      personType: "F",
      step: 1,
      1: {},
      2: {},
      3: {},
      4: {},
      5: {}
    }
  },
  methods: {
    async confirmStep(value) {
      if (!value) return
      const steps = this.gestor.getComponentSteps();

      this[this.gestor.getCurrentStep()] = value.data

      this.gestor.nextStep();
      if (!this.gestor.isLastStep()) return

      this.loading = true
      try {
        const parametros = Object.keys(steps).reduce((prev, curr, index) => {
          prev[curr] = this[steps[curr].id]
          return prev
        }, {})

        const response = await this.gestor.signUpService(parametros)
        this.response = response
      } catch (error) {
        throw new Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    currentComponent() {
      if(!this.gestor.components) return "";
      return this.gestor.getCurrentComponent();
    }
  }
}
</script>

<style lang="css" scoped>
.signup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5% 0;
}

.copyright {
  font-size: 0.8rem;
}
.max-obd-width {
  max-width: 610px;
}
.logo-six-clovers{
  width: 275px;
}
</style>
